<template>
	<v-sheet class="payment" id="payment" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form ref="paymentForm" v-model.trim="formValid" lazy-validation>
					<div style="max-width: 768px">
						<table width="100%" style="table-layout: fixed">
							<tr>
								<td width="30%">
									<label for="invoice" class="btx-label mt-2">Invoice</label>
								</td>
								<td width="70%">
									<AutoCompleteInput
										hide-details
										:items="invoiceList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="invoice"
										item-text="barcode"
										item-value="id"
										placeholder="Invoice"
										v-model="payment.invoice"
										readonly
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="date" class="btx-label mt-2">Invoice Date</label>
								</td>
								<td>
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="date"
										placeholder="Invoice Date"
										v-model="payment.invoice_date"
										readonly
									></DatePicker>
								</td>
							</tr>
							<tr>
								<td>
									<label for="total-amount" class="btx-label mt-2">Total Amount</label>
								</td>
								<td>
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="total-amount"
										placeholder="Total Amount"
										v-model="payment.invoice_amount"
										readonly
									></PriceInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="paid-amount" class="btx-label mt-2">Paid Amount</label>
								</td>
								<td>
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="paid-amount"
										placeholder="Paid Amount"
										v-model="payment.invoice_paid_amount"
										readonly
									></PriceInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="amount-received" class="btx-label mt-2 required">Amount Received</label>
								</td>
								<td>
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="amount-received"
										placeholder="Amount Received"
										v-model="payment.p_amount"
										:rules="[vrules.required(payment.p_amount, 'Amount Received')]"
										:class="{ required: !payment.p_amount }"
										v-on:keyup="validateAmount()"
									></PriceInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="payment-mode" class="btx-label mt-2 required">Payment Mode</label>
								</td>
								<td>
									<AutoCompleteInput
										hide-details
										:items="paymentModeList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="payment-mode"
										placeholder="Payment Mode"
										v-model="payment.p_mode"
										:rules="[vrules.required(payment.p_mode, 'Payment Mode')]"
										:class="{ required: !payment.p_mode }"
										append-outer-icon="mdi-cog"
										v-on:click:append-outer="managePaymentModeDialog = true"
									></AutoCompleteInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="payment-date" class="btx-label mt-2 required">Payment Date</label>
								</td>
								<td>
									<DatePicker
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="payment-date"
										placeholder="Payment Date"
										v-model="payment.p_date"
										:rules="[vrules.required(payment.p_date, 'Payment Date')]"
										:class="{ required: !payment.p_date }"
									></DatePicker>
								</td>
							</tr>
							<tr>
								<td>
									<label for="remark" class="btx-label mt-2">Remarks</label>
								</td>
								<td>
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:rows="2"
										id="remark"
										placeholder="Remarks"
										v-model="payment.remark"
									></TextAreaInput>
								</td>
							</tr>
							<tr>
								<td>
									<label for="attachments" class="btx-label mt-2">Attachments</label>
								</td>
								<td>
									<FileUpload v-model="payment.attachment"></FileUpload>
								</td>
							</tr>
						</table>
					</div>
				</v-form>
			</v-col>
		</v-row>
		<template v-if="managePaymentModeDialog">
			<ManagePaymentMode
				:dialog="managePaymentModeDialog"
				:list="paymentModeList"
				v-on:close="managePaymentModeDialog = false"
				v-on:success="getOption('payment-mode')"
			></ManagePaymentMode>
		</template>
	</v-sheet>
</template>
<script>
// import { findIndex, toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import ManagePaymentMode from "@/view/components/Manage-Payment-Mode";
import TextAreaInput from "@/view/components/TextAreaInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import DatePicker from "@/view/components/DatePicker";
import FileUpload from "@/view/components/FileUpload";
import PriceInput from "@/view/components/PriceInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";

export default {
	name: "payment-create",
	title: "Create Payment",
	data() {
		return {
			uuid: null,
			serviceUUID: null,
			cloneUUID: null,
			barcode: null,
			formValid: true,
			pageLoading: false,
			updatePayment: false,
			managePaymentModeDialog: false,
			paymentModeList: [],
			invoiceList: [],
			payment: {
				invoice: 0,
				invoice_date: null,
				invoice_paid_amount: 0,
				to_be_paid: 0,
				invoice_amount: 0,
				p_amount: 0,
				p_mode: 0,
				p_date: null,
				remark: null,
			},
		};
	},
	components: {
		DatePicker,
		FileUpload,
		PriceInput,
		TextAreaInput,
		AutoCompleteInput,
		ManagePaymentMode,
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		initialize() {
			this.paymentModeList = this.localDB("payment_modes", []);
		},
		async updateOrCreate() {
			try {
				const _this = this;

				const formErrors = _this.validateForm(_this.$refs.paymentForm);

				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.paymentForm.validate()) {
					return false;
				}

				this.pageLoading = true;

				let requestURL = "payment";

				if (this.uuid) {
					requestURL = `payment/${this.uuid}`;
				}

				const formData = new FormData();

				formData.append("invoice", this.payment.invoice);
				formData.append("p_amount", this.payment.p_amount);
				formData.append("p_mode", this.payment.p_mode);
				formData.append("p_date", this.payment.p_date);
				if (this.payment.remark) {
					formData.append("remark", this.payment.remark);
				}

				if (this.payment.attachment && this.payment.attachment.length) {
					for (let i = 0; i < this.payment.attachment.length; i++) {
						if (this.payment.attachment[i].file) {
							formData.append(`attachments[${i}][file]`, this.payment.attachment[i].file);
							formData.append(`attachments[${i}][name]`, this.payment.attachment[i].name);
							formData.append(`attachments[${i}][suffix]`, this.payment.attachment[i].name);
						}
					}
				}

				ApiService.upload(requestURL, formData)
					.then(() => {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Payment has been saved." },
						]);

						_this.$router.replace({
							name: "payment",
							query: { t: new Date().getTime() },
						});
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.pageLoading = false;
					});
			} catch (error) {
				this.logError(error);
			}
		},
		validateAmount() {
			const to_be_paid = ObjectPath.get(this, "payment.to_be_paid", 0);
			const p_amount = ObjectPath.get(this, "payment.p_amount", 0);
			if (p_amount > to_be_paid) {
				this.payment.p_amount = to_be_paid;
			}
		},
		initPayment() {
			this.pageLoading = true;
			ApiService.query("payment/option", this.payment)
				.then((output) => {
					this.invoiceList = ObjectPath.get(output, "data.invoices");
					if (!this.uuid) {
						this.payment.invoice = ObjectPath.get(output, "data.invoice.id");
						this.payment.invoice_date = ObjectPath.get(output, "data.invoice.date");
						this.payment.invoice_amount = ObjectPath.get(output, "data.invoice.total_amount");
						this.payment.invoice_paid_amount = ObjectPath.get(output, "data.invoice.paid_amount");
						this.payment.to_be_paid = ObjectPath.get(output, "data.invoice.to_be_paid");
						this.payment.p_date = ObjectPath.get(output, "data.p_date");
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getPayment() {
			ApiService.get(`payment/${this.uuid}`)
				.then((output) => {
					this.barcode = ObjectPath.get(output, "data.barcode");
					this.payment.invoice = ObjectPath.get(output, "data.invoice");
					this.payment.invoice_date = ObjectPath.get(output, "data.invoice_relation.date");
					this.payment.invoice_paid_amount = ObjectPath.get(output, "data.invoice_relation.paid_amount");
					this.payment.to_be_paid = ObjectPath.get(output, "data.invoice_relation.to_be_paid");
					this.payment.invoice_amount = ObjectPath.get(output, "data.invoice_relation.total_amount");
					this.payment.p_amount = ObjectPath.get(output, "data.p_amount");
					this.payment.p_mode = ObjectPath.get(output, "data.p_mode");
					this.payment.p_date = ObjectPath.get(output, "data.p_date");
					this.payment.remark = ObjectPath.get(output, "data.remark");
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Payment", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getClonePayment() {
			ApiService.get(`payment/${this.cloneUUID}`)
				.then((output) => {
					this.payment.invoice = ObjectPath.get(output, "data.invoice");
					this.payment.invoice_date = ObjectPath.get(output, "data.invoice_relation.date");
					this.payment.invoice_paid_amount = ObjectPath.get(output, "data.invoice_relation.paid_amount");
					this.payment.to_be_paid = ObjectPath.get(output, "data.invoice_relation.to_be_paid");
					this.payment.invoice_amount = ObjectPath.get(output, "data.invoice_relation.total_amount");
					this.payment.p_amount = ObjectPath.get(output, "data.p_amount");
					this.payment.p_mode = ObjectPath.get(output, "data.p_mode");
					this.payment.p_date = ObjectPath.get(output, "data.p_date");
					this.payment.remark = ObjectPath.get(output, "data.remark");
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Payment - " + this.barcode;
			}
			return "Create new Payment";
		},
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		stringId() {
			return this.stringUnique();
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Payment", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;

		if (name === "payment-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getPayment();
		}

		this.initialize();

		if (this.uuid) {
			this.updatePayment = true;
		}

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");
		this.payment.invoice = ObjectPath.get(this.$route, "query.invoice");

		if (this.cloneUUID) {
			this.getClonePayment();
		}

		this.initPayment();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
